import React from "react";
import { NavigationDots, SocialMedia } from "../components";
import { AiOutlineArrowUp } from "react-icons/ai";

const AppWrap = (Component, idName, classNames, ifSocial = true) =>
  function HOC() {
    return (
      <div id={idName} className={`app__container ${classNames}`}>
        {ifSocial && <SocialMedia />}
        <div className="app__wrapper app__flex">
          <Component />

          <div className="copyright">
            {!ifSocial && (
              <>
                <p className="p-text">
                  No © copyright issues. Feel free to copy.
                </p>
                <p className="p-text">
                  If you need any help, you can contact me!
                </p>
              </>
            )}
            <p className="p-text">
              <a href="#home">
                <AiOutlineArrowUp /> Scroll to Top
              </a>
            </p>
          </div>
        </div>
        <NavigationDots active={idName} />
      </div>
    );
  };

export default AppWrap;
