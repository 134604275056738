import React, { useState, useEffect } from "react";
import { AppWrap, MotionWrap } from "../../wrapper";
import "./Blog.scss";
import Article from "./Article";

const Blog = () => {
  const url =
    "https://seekersahil.com/wp-json/wp/v2/posts?categories=345&per_page=5";
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setBlogPosts(data.sort((a, b) => a.date - b.date));
      });
  }, []);
  return (
    <>
      <h2 className="head-text">
        Blog<span> Posts </span>
      </h2>

      <div className="app__blog-all">
        <a
          href="https://seekersahil.com/technology"
          target="_blank"
          rel="noreferrer"
          className="app__blog-all-button"
        >
          All Posts
        </a>
      </div>
      <div className="app__blog-container">
        <div className="app__blog-container_groupA">
          <Article post={blogPosts[0]} />
        </div>
        <div className="app__blog-container_groupB">
          {blogPosts.map((blog, index) => {
            if (index > 0) return <Article post={blog} />;
          })}
        </div>
      </div>
    </>
  );
};

export default AppWrap(MotionWrap(Blog, "app__blog"), "blog", "app__primarybg");
