import React from "react";
import { AiFillRead } from "react-icons/ai";
import { motion } from "framer-motion";
import "./Blog.scss";

const Article = (props) => {
  let { post } = props;
  if (post) {
    let title = post?.title.rendered,
      postDate = post?.date,
      imgURL = post?.yoast_head_json.og_image[0].url,
      postURL = post?.link;
    let date = new Date(postDate);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let blogDate = `${date.getDate()} ${
      monthNames[date.getMonth()]
    }, ${date.getFullYear()}`;
    return (
      <div className="app__blog-container_article" key={`blogpost-${post?.id}`}>
        <div className="app__blog-container_article-image">
          <img src={imgURL} alt={title} />
        </div>
        <div className="app__blog-container_article-content">
          <div>
            <p>{blogDate}</p>
            <h3>{title}</h3>
          </div>
          <a href={postURL} target="_blank" rel="noreferrer">
            Read Full Article
          </a>
        </div>
      </div>
    );
  }
};

// const Article = (props) => {
//   let { post } = props;
//   console.log(post);
//   let title = post.title.rendered,
//     postDate = post.date,
//     imgURL = post.yoast_head_json.og_image[0].url,
//     postURL = post.link;

//   let date = new Date(postDate);
//   const monthNames = [
//     "January",
//     "February",
//     "March",
//     "April",
//     "May",
//     "June",
//     "July",
//     "August",
//     "September",
//     "October",
//     "November",
//     "December",
//   ];
//   let blogDate = `${date.getDate()} ${
//     monthNames[date.getMonth()]
//   }, ${date.getFullYear()}`;
//   return (
//     <div className="app__blog-item app__flex" key={`blogpost-${post.id}`}>
//       <div className="app__blog-img app__flex">
//         <img src={imgURL} alt={title} />
//         <motion.div
//           whileHover={{ opacity: [0, 1] }}
//           transition={{
//             duration: 0.25,
//             ease: "easeInOut",
//             staggerChildren: 0.5,
//           }}
//           className="app__blog-hover app__flex"
//         >
//           <a href={postURL} target="_blank" rel="noreferrer">
//             <motion.div
//               whileInView={{ scale: [0, 1] }}
//               whileHover={{ scale: [1, 0.9] }}
//               transition={{ duration: 0.25 }}
//               className="app__flex"
//             >
//               <AiFillRead />
//             </motion.div>
//           </a>
//         </motion.div>
//       </div>

//       <div className="app__blog-content app__flex">
//         <h4 className="bold-text">{title}</h4>
//         <a
//           className="p-text"
//           style={{ marginTop: "10px" }}
//           href={postURL}
//           target="_blank"
//           rel="noreferrer"
//         >
//           Read Full Article
//         </a>
//         <div className="app__blog-tag app__flex">
//           <p className="p-text">{blogDate}</p>
//         </div>
//       </div>
//     </div>
//   );
// };

export default Article;
